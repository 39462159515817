<script setup lang="ts">
const emit = defineEmits<{
	(event: "moreInfo"): void;
	(event: "cardClick"): void;
}>();

const { open } = useTaoModals();
const { t } = useT();
const { data, activeCard, durationLeft } = useScratchCard();

const goldenCardData = computed(() =>
	data.value?.cards?.find((card) => card?.slug === "gold" && card?.status === "completed")
);

const claimStatus = computed(() => activeCard.value?.status === "ready_for_activate");

const btnText = computed(() => (claimStatus.value ? t("Claim") : t("Scratch & Win")));

const { durationLeft: goldenCardTimeLeft, reset: resetGoldenCardTimeLeft } = useCountdown({
	timestamp: goldenCardData.value?.timer as string,
	formatToken: "HH[:]mm[:]ss"
});

const handleCardClick = () => {
	if (goldenCardData.value) {
		return;
	}

	emit("cardClick");
	if (claimStatus.value) {
		open("LazyOModalScratchCardsPlayForWin", { cardType: activeCard.value?.slug });
		return;
	}
	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};

watch(
	() => goldenCardData.value?.timer,
	() => {
		resetGoldenCardTimeLeft(goldenCardData.value?.timer ?? "");
	}
);
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 280,
			height: 160,
			src: '/nuxt-img/scratch-cards/promo/ticket-card.png',
			alt: 'scratch card',
			format: 'avif',
			loading: 'lazy'
		}"
		width="328px"
		height="325px"
		class="card-scratch"
		@click="handleCardClick"
	>
		<template #top>
			<ABadge background="var(--a-overlay-100)" variant="info" autosize>
				<AText type="x-small" as="div">
					<i18n-t keypath="Time left {key}">
						<template #key>
							<b>{{ durationLeft }}</b>
						</template>
					</i18n-t>
				</AText>
			</ABadge>
			<AText type="small" :modifiers="['bold', 'uppercase']">
				{{ t("Unlock scratch cards") }}
			</AText>
		</template>
		<template #bottom>
			<div class="box">
				<MScratchCardsPrizesInfo
					class="promo-prizes mb-1"
					:entries="activeCard?.prize?.entries ?? 0"
					:coins="activeCard?.prize?.coins ?? 0"
					titleWeight="normal"
					titleVariant="xx-small-secondary"
					prizeVariant="h6"
				/>
				<MScratchCardsProgressBar
					v-if="!claimStatus"
					:value="activeCard?.progress?.pointsComplete ?? 0"
					:maxValue="activeCard?.progress?.points ?? 0"
				/>
				<div v-else class="space" />
			</div>

			<AButton :variant="'primary'" :modifiers="[!!goldenCardData?.timer ? 'disabled' : '', 'wide']" size="s">
				{{ !goldenCardData ? btnText : goldenCardTimeLeft }}
			</AButton>
			<AText type="x-small" :modifiers="['uppercase', 'link-underline', 'bold']" @click.stop="emit('moreInfo')">
				{{ t("Visit super scratchers page") }}
			</AText>
		</template>
	</ACard>
</template>

<style lang="scss" scoped>
.card {
	--a-card-default-radius: 16px;
	--a-card-default-top-gap: 12px;
	--a-card-default-bottom-gap: 12px;
	--a-card-default-bottom-padding: 0 24px 16px;
	--a-badge-info-padding: 2px 4px;

	background: linear-gradient(150deg, #d250ff 0%, #713ade 53%, #001038 100%);
}

.space {
	height: 16px;
}

.box {
	width: 100%;
}

.card-scratch {
	.promo-prizes {
		gap: 0;

		&:deep(.prizes) {
			flex-direction: row;

			.nuxt-icon {
				font-size: 16px;
			}
		}

		&:deep(img) {
			width: 14px;
			height: 14px;
		}
	}
}
</style>
